<template>
  <div class="text-center" style="padding-top: 3rem; margin-bottom: 4rem">
    <p style="font-size: 0.8rem; font-weight: bold" class="ml-2 my-4">
      {{ $t("lp_detail_show_also") }}
    </p>
    <v-row>
      <v-col cols="12" md="10" offset-md="1">
        <div
          v-for="(alsoRoute, index) in seeAlsoRoutes"
          :key="alsoRoute.route"
          class="d-inline"
        >
          <v-btn
            text
            x-small
            :to="{
              name: alsoRoute.route,
              params: { from_section: back_section },
            }"
            href="http://google.it"
            >{{ alsoRoute.name }}</v-btn
          >
          <!-- @click="navigateTo(alsoRoute.route, back_section)" -->
          <!-- <a
            :href="{
              name: alsoRoute.route,
              params: { from_section: back_section },
            }"
          >LINK</a> -->
          <span v-if="index < seeAlsoRoutes.length - 1" class="mr-1"> </span>
        </div>
      </v-col>
    </v-row>

    <div style="height: 8rem"></div>
  </div>
</template>

<script>
export default {
  props: {
    back_section: {
      type: String,
    },
  },
  components: {},
  data: () => ({}),
  methods: {
    composeLink() {},
    navigateTo(to, from) {
      this.$router.push({ name: to, params: { from_section: from } });
    },
  },
  mounted() {
    console.log("seeAlso back_section", this.back_section);
  },
  created() {},
  computed: {
    othersNamedRoutes() {
      return [
        {
          route: "HowToManageAreas",
          name: this.$t("lp_route_title_area_managment"),
        },
        {
          route: "HowToManageReports",
          name: this.$t("lp_route_title_report_managment"),
        },
        {
          route: "HowToManageEvents",
          name: this.$t("lp_route_title_event_managment"),
        },
        {
          route: "LandingFeatures",
          name: this.$t("lp_route_title_features"),
        },
        {
          route: "HowToManageSharing",
          name: this.$t("lp_route_title_sharing"),
        },
        {
          route: "HowToManageDashboard",
          name: this.$t("lp_route_title_dashboarding"),
        },
        {
          route: "LandingPrices",
          name: this.$t("lp_route_title_prices"),
        },
      ];
    },
    seeAlsoRoutes() {
      return this.othersNamedRoutes.filter((route_name) => {
        // console.log(route_name, this.currentRouteName);
        if (route_name.route != this.currentRouteName) return true;
      });
    },
  },
  watch: {},
};
</script>

<style>
</style>