<template>
  <div>
    <v-btn
      class="btn_back_home my-4"
      fab
      right
      :to="{
        name: 'LandingHome',
        params: { scroll_to_section_id: back_section },
      }"
      color="primary"
      style="
        border-color: yellow !important;
        border-width: 3px !important;
        border-style: solid !important;
        z-index: 10 !important;
      "
    >
      <v-icon>mdi-home</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["back_section"],
  components: {},
  data: () => ({}),
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style>
.btn_back_home {
  position: fixed;
  bottom: 16px;
  right: 20px;
}
</style>